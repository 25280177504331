<template>
  <div class="card mb-2">
    <div class="card-header"><span class="font-weight-bold">{{ selectedEmail.caption }}</span>&nbsp;</div>
    <div class="card-header">
      <div class="row justify-content-between">
        <div class="col-1">
          <el-avatar size="small" :src="'https://api.dicebear.com/9.x/initials/svg?seed=' + selectedEmail.contact_display_name"></el-avatar>
        </div>
        <div class="col-8">
          <div class="ml-2">
            <p>
              <span class="font-weight-bold">{{ selectedEmail.contact_display_name }}</span>&nbsp;
              <span class="text-muted tx-13">&lt;{{ selectedEmail.contact.phone_number }}&gt;</span>
            </p>
            <p class="text-muted tx-13">
              <span>To:
                <el-tag size="mini" type="info" class="mr-1 mb-1" :key="'to-' + e + index" v-for="(e, index) in selectedEmail.metadata.to">{{ e }}</el-tag>
              </span>
            </p>
            <p class="text-muted tx-13">
              <span v-if="selectedEmail.metadata.cc && selectedEmail.metadata.cc.length">Cc:
                <el-tag size="mini" type="info" class="mr-1 mb-1" :key="'cc-' + e + index" v-for="(e, index) in selectedEmail.metadata.cc">{{ e }}</el-tag>
              </span>
            </p>
          </div>
        </div>
        <div class="col-3">
          <span class="text-muted tx-12">
            {{ moment(selectedEmail.created_at).format('DD MMM YYYY, HH:mm') }}
            <i class="mdi" :class="{
              'mdi-check-all': ['sent', 'delivered', 'read'].includes(selectedEmail.status),
              'mdi-close-circle-outline text-danger': selectedEmail.status === 'failed',
            }"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div ref="emailContainer" v-html="processedEmail(selectedEmail.body, selectedEmail.id)"></div>
      <el-divider/>
      <template v-if="selectedEmail.attachments.length > 0">
        <div class="row">
          <div class="col-4" v-for="(item, i) in selectedEmail.attachments" :key="i">
            <email-attachment-item
              :fileName="item.filename"
              :fileSize="item.size"
              :fileUrl="item.url"
              />
          </div>
        </div>
        <el-divider/>
        <!-- {{ selectedEmail.attachments }} -->
      </template>
      <div class="email-actions">
        <el-button size="mini" @click="$emit('onClickReply', selectedEmail)"><font-awesome icon="reply"/> Reply</el-button>
        <!-- <el-button size="mini"><font-awesome icon="reply-all"/> Reply all</el-button>
        <el-button size="mini"><font-awesome icon="share"/> Forward</el-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import { truncate } from 'lodash';
import moment from 'moment';
import EmailAttachmentItem from './EmailAttachmentItem.vue';

export default {
  name: 'MessageItemEmail',
  components: {
    EmailAttachmentItem,
  },
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      moment,
      truncate,
      selectedEmail: {
        contact: {},
        metadata: {},
        attachments: [],
      },
    };
  },
  mounted() {
    this.selectedEmail = this.message;
    if (this.selectedEmail.type === 'out') {
      this.selectedEmail.contact_display_name = this.selectedEmail.user.name;
    } else {
      this.selectedEmail.contact_display_name = this.selectedEmail.contact.name;
    }
    this.selectedEmail.attachments = this.selectedEmail.metadata.attachments;
    this.$nextTick(() => {
      this.$refs.emailContainer.addEventListener('click', this.handleThreadToggle);
    });
  },
  methods: {
    processedEmail(content, email_id) {
      return this.truncateEmailThread(content, email_id);
    },
    truncateEmailThread(html, email_id) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // Select common quoted email elements
      const quoteSelectors = [
        '.history-quote-wrapper', // Lark Mail
        '.gmail_quote', // Gmail
        'blockquote', // Outlook/Gmail
        'div[style*="border-left"]', // Generic quoted text
      ];

      quoteSelectors.forEach((selector, idx) => {
        doc.querySelectorAll(selector).forEach((elem, id2) => {
          // Wrap quoted content in a div and hide it
          const hiddenDiv = document.createElement('div');
          hiddenDiv.className = 'hidden-thread';
          hiddenDiv.id = `hidden-thread-${idx}-${id2}-${email_id}`;
          hiddenDiv.style.display = 'none';
          hiddenDiv.innerHTML = elem.innerHTML;

          // Create the ellipsis button
          const button = document.createElement('button');
          button.className = 'el-button el-button--text toggle-thread';
          button.innerText = '...';
          button.id = `toggle-thread-${idx}-${id2}-${email_id}`;
          button.dataset.target = `toggle-thread-${idx}-${id2}-${email_id}`;

          // Replace quoted content with button + hidden div
          elem.innerHTML = '';
          elem.appendChild(button);
          elem.appendChild(hiddenDiv);
        });
      });

      return doc.body.innerHTML;
    },
    handleThreadToggle(event) {
      if (event.target.classList.contains('toggle-thread')) {
        const threadId = event.target.dataset.target;
        const thread = document.getElementById(threadId.replace('toggle', 'hidden'));

        if (thread) {
          if (thread.style.display === 'none') {
            thread.style.display = 'block';
            event.target.innerText = 'Hide';
          } else {
            thread.style.display = 'none';
            event.target.innerText = '...';
          }
        }
      }
    },
  },
};
</script>
