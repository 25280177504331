<template>
  <el-card class="attachment-card">
    <div class="attachment-container">
      <!-- <el-avatar :size="30" :src="iconSrc" class="attachment-icon"></el-avatar> -->
      <div class="attachment-info">
        <span class="attachment-name">{{ fileName }}</span>
        <span class="attachment-size">{{ humanFileSize(fileSize) }}</span>
      </div>
      <el-button
        type="text"
        icon="el-icon-download"
        class="download-button"
        @click="downloadFile"
      ></el-button>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'EmailAttachmentItem',
  props: {
    fileName: {
      type: String,
      required: true,
    },
    fileSize: {
      type: Number,
      required: true,
    },
    fileUrl: {
      type: String,
      required: true,
    },
    iconSrc: {
      type: String,
      default: 'https://cdn-icons-png.flaticon.com/512/337/337946.png',
    },
  },
  methods: {
    async downloadFile() {
      const split_url = this.fileUrl.split('/');
      const file_name = split_url[split_url.length - 1];
      const blob = await (await fetch(`/proxy?img=${this.fileUrl}`)).blob();
      const url = URL.createObjectURL(blob);
      Object.assign(document.createElement('a'), { href: url, download: file_name }).click();
      URL.revokeObjectURL(url);
    },
    /* eslint-disable no-restricted-properties */
    /* eslint-disable prefer-template */
    humanFileSize(size) {
      if (!size) {
        return 'unknown';
      }
      const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return +((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    },
  },
};
</script>

<style scoped>
.attachment-card {
  /* width: 300px; */
  /* padding: 10px; */
  border-radius: 8px;
}

.attachment-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.attachment-icon {
  background-color: #f4f4f4;
  border-radius: 5px;
}

.attachment-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.attachment-name {
  font-weight: 500;
  font-size: 12px;
}

.attachment-size {
  font-size: 10px;
  color: gray;
}

.download-button {
  /* font-size: 16px; */
  color: #409eff;
}
</style>
